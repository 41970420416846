import axios from 'axios';

const api = axios.create({
  // dev
  // baseURL: 'https://api.dev.deixanoazul.com.br/api/',

  // prod
  baseURL: 'https://v2.api.app.deixanoazul.com.br/api/',

  // teste
  // baseURL: 'https://api.teste.deixanoazul.com.br/api/',
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response.data.message === 'Token has expired' ||
      error.response.data.message === 'Token not provided'
    ) {
      // alert('Seu token de autenticação expirou, clique em "Ok", atualize a página e faça login novamente.')
      localStorage.removeItem('@DeixaNoAzul:accessToken');
      localStorage.removeItem('@DeixaNoAzul:loggedUser');
      localStorage.removeItem('@DeixaNoAzul:licensedId');
      localStorage.removeItem('@DeixaNoAzul:estabelecimentoId');
    }

    return Promise.reject(error);
  }
);

export default api;
